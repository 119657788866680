@import "../../../styles/utils";

.register-box {
    width: 75rem;
    height: auto;

    padding: 2.6rem;
    border-radius: .6rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.with-map {
        left: 35%;
    }

    @include tablet {
        width: 95vw;
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
        margin: 5rem auto;
        padding-bottom: 5rem;
    }
}

.login-logo {
    display: block;
    width: 18rem;
    height: 8rem;
    margin: 0 auto 3rem auto;
    //background-color: $primary;
}

.steps-line {
    height: .2rem;
    width: 4rem;
    background-color: rgba($primary, .3);
}

.maps-drawer {
    height: 100vh;
    position: absolute;
    right: 0;
    .drawer-content {
        padding: 0;
    }
}

[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}

[type="file"] + label {
    border: none;
    border-radius: 5px;
    color: #fff;
    //Starts
    //margin: 0 auto; /* Center align the label */
    //display: block; /* Make label a block element to allow margin auto to work */
    //position: absolute; /* Position the label absolutely */
    //top: 50%; /* Position halfway down the parent */
    //left: 50%; /* Position halfway across the parent */
    //transform: translate(-50%, -50%); /* Adjust position to truly center */
    //width: fit-content; /* Adjust width to fit content */
    ////ends
    cursor: pointer;
    display: inline-block;
    font-family: 'Rubik', sans-serif;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    padding: 1rem 50px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;


    &.btn-2 {
        background-color: $primary-light;
        border-radius: 50px;
        overflow: hidden;

        &::before {
            color: #fff;
            content: "📥";
            font-size: 100%;
            height: 100%;
            right: 120%;
            line-height: 3.3;
            position: absolute;
            top: 0px;
            transition: all 0.3s;
        }

        &:hover {
            background-color: darken($primary-dark, 10%);

            &::before {
                right: 80%;
            }
        }
    }
}

.removeButton{
  background-color: #af0b0b;
  border-radius: 2rem;
  overflow: hidden;

  span {
      display: inline-block;
      height: 100%;
      transition: all 0.3s;
      width: 100%;
  }

  &::before {
      color: #fff;
      content: "X";
      font-size: 130%;
      height: 100%;
      left: 0;
      line-height: 2.6;
      position: absolute;
      top: -180%;
      transition: all 0.3s;
      width: 100%;
  }

  &:hover {
      background-color: darken(#ee6d9e, 30%);

      span {
          transform: translateY(300%);
      }

      &::before {
          top: 0;
      }
  }

}
.image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; // Spacing between each image container
}

.uploaded-image {
    width: 100px;
    height: 100px;
    border-radius: .6rem;
    object-fit: cover; // To maintain aspect ratio
    order: 1;
}

.remove-button {
    margin-right: 10px; // Space between button and image
    padding: 5px 10px;
    border: none;
    background-color: $red-light; // Example color
    color: white;
    border-radius: .6rem;
    cursor: pointer;
    order: 2;

    &:hover {
        background-color: $favorite; // Slightly lighter on hover
    }
}

// Subscription Component Styles
.subscription-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.subscription-option {
    position: relative; // Needed for absolute positioning of the badge
    border: 2px solid #e7e7e7;
    border-radius: 8px;
    margin: 1rem 0;
    padding: 2rem;
    width: 90%;
    max-width: 350px;
    transition: border-color 0.3s ease;

    &:hover {
        border-color: $primary;
    }
}

.subscription-details {
    text-align: center;
}

.subscription-title {
    color: $secondary;
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.subscription-price {
    color: $primary-dark;
    font-size: 2.6rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.subscription-description {
    color: #666;
    margin-bottom: 2.5rem;
}

.subscribe-button {
    background-color: $primary;
    border: none;
    margin-top: 1rem;
    border-radius: 4px;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1.6rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: $primary-dark;
    }
}

// Active Subscription Styles
.subscription-option.active {
    border-color: $primary-light;
}

.active .subscription-title,
.active .subscription-price {
    color: $primary-light;
}

.active .subscribe-button {
    background-color: $primary-light;
    &:hover {
        background-color: $primary;
    }
}
.subscription-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    position: absolute; // Make sure the parent is relative.
    top: -10px;
    right: -10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.subscribe-button.dimmed {
    background-color: #ccc;
    cursor: not-allowed;

    &:hover {
        background-color: #ccc;
    }
}


// Active Plan button
.manage-subscription-button {
    background-color: $secondary-light;
    &:hover {
        background-color: darken($secondary-light, 5%);
    }
}

.subscription-option.active {
    border-color: $primary;
    background-color: $primary-light;
    background-color: rgba($primary-light, 0.2); // Slight background tint
}

// Overlay
.subscription-overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
    z-index: 1000; // Ensure it's above other content
}

// Popup
.subscription-popup {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    text-align: center;
    position: relative;
}

.subscription-popup-header {
    font-size: 2rem;
    color: $primary-dark;
    margin-bottom: 1.2rem;
}

.subscription-popup-content {
    margin-bottom: 2rem;
}

.subscription-popup-actions {
    display: flex;
    justify-content: space-evenly;
}

.subscription-action-button {
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0.5rem;
}

.cancel-subscription-button {
    background-color: $favorite;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($favorite, 10%);
    }
}

.switch-subscription-button {
    background-color: $secondary;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($secondary, 10%);
    }
}

.close-button {
    position: absolute;
    top: 2px;
    right: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 3.5rem;
    color: $secondary;
}

/* Ensure the base styling is included before these enhancements */

.upload-section {
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
}

.upload-instructions {
    color: #333;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.file-upload {
    border: 2px dashed #cccccc;
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    position: relative;
    background-color: #fff;
    transition: border-color 0.3s;
}

.file-upload:hover {
    border-color: $primary;
}

.file-upload input[type="file"] {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.uploaded-image-preview {
    margin-top: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0.5rem;
}

.upload-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.upload-control-button {
    background-color: $primary;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    color: white;
    font-weight: bold;
}

/* Specific button modifications for 'Remove', 'Clear', 'Next' */
.upload-control-button.remove,
.upload-control-button.clear {
    background-color: $favorite; /* Assuming this is a red color for 'Remove' actions */
}

.upload-control-button.next {
    background-color: $secondary; /* A different color indicating forward action */
}

/* Step indicators enhancement */
.step-indicator {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.step-indicator .step {
    width: 25px;
    height: 25px;
    background-color: #ddd;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
}

.step-indicator .step.active {
    background-color: $primary;
}
.bottom-container {
    display: flex;
    justify-content: space-between; /* Aligns children on opposite ends */
    align-items: center;
    padding-top: 1rem; /* Add space above the bottom container */
}

.login-link {
    color: $primary; /* Style as needed */
    text-decoration: none; /* Remove underline from link */
    padding: 0.5rem 1rem; /* Add padding for better click area and visibility */
    /* Align to the left */
}