.submenu-layout {
  display: flex;
  margin-left: -10px;
  gap: 2%; // This will create space between children
}

.menu-preview {
  width: 30%;
  max-width: 375px; /* Maximum width can be the width of a typical mobile device */
  height: 30vh; /* Sets the height to 30% of the viewport height */
  /* Other styles remain the same */
}

.submenu-content{
  width: 100%;
  margin-left: 20px;
}

.products-text{
  margin-right: auto;
}
.back-button {
  padding: 3px;
  margin-right: 2px; // Space between the back button and title or other content
  font-size: 16px; // Match this to your design
  cursor: pointer; // To indicate it's clickable
  background-color: transparent; // Or any color that fits your design
  border: none; // Unless you want a border

  &:hover {
    text-decoration: underline; // Optional: if you want to indicate hover state
  }

  // If using an icon, you might add something like this:
  .icon {
    margin-right: 5px; // If you have text next to an icon, space them out
    // Other icon styles
  }
}