@import "../../../styles/utils";

.image-preview {

    max-width: 130px;
    max-height: 130px;
    background-position: center;
    background-size: contain;
}


.submenus-layout {
    display: flex;
    margin-left: -10px;
    gap: 2%; // This will create space between children
}

.menu-preview {
    width: 30%;
    max-width: 375px; /* Maximum width can be the width of a typical mobile device */
    height: 30vh; /* Sets the height to 30% of the viewport height */
    /* Other styles remain the same */
}

.submenu-content{
    width: 100%;
    margin-left: 20px;
}
.time-warning {
    padding: 10px;
    margin-top: 10px;
    background-color: #ffca51; /* A noticeable warning color, you can adjust as needed */
    border-left: 5px solid #ff9900; /* A darker shade for contrast */
    color: #333; /* Dark text for readability */
    border-radius: 5px; /* Optional: Rounds the corners for a smoother look */
    font-size: 0.9em; /* Adjust the font size as needed */
    line-height: 1.4; /* Improved readability for the warning text */
}

.submenu-text{
    margin-right: auto;
}
.back-button {
    padding: 3px;
    margin-right: 2px; // Space between the back button and title or other content
    font-size: 16px; // Match this to your design
    cursor: pointer; // To indicate it's clickable
    background-color: transparent; // Or any color that fits your design
    border: none; // Unless you want a border

    &:hover {
        text-decoration: underline; // Optional: if you want to indicate hover state
    }

    // If using an icon, you might add something like this:
    .icon {
        margin-right: 5px; // If you have text next to an icon, space them out
        // Other icon styles
    }
}

// The following styles are for the menu upload


.upload-section {
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
}

.upload-instructions {
    color: #333;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.file-upload {
    border: 2px dashed #cccccc;
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    position: relative;
    background-color: #fff;
    transition: border-color 0.3s;
}

.file-upload:hover {
    border-color: $primary;
}

.file-upload input[type="file"] {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.uploaded-image-preview {
    margin-top: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0.5rem;
}

.upload-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.upload-control-button {
    background-color: $primary;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    color: white;
    font-weight: bold;
}

/* Specific button modifications for 'Remove', 'Clear', 'Next' */
.upload-control-button.remove,
.upload-control-button.clear {
    background-color: $favorite; /* Assuming this is a red color for 'Remove' actions */
}

.upload-control-button.next {
    background-color: $secondary; /* A different color indicating forward action */
}

/* Step indicators enhancement */
.step-indicator {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.step-indicator .step {
    width: 25px;
    height: 25px;
    background-color: #ddd;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
}

.step-indicator .step.active {
    background-color: $primary;
}


[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}

[type="file"] + label {
    border: none;
    border-radius: 5px;
    color: #fff;
    //Starts
    //margin: 0 auto; /* Center align the label */
    //display: block; /* Make label a block element to allow margin auto to work */
    //position: absolute; /* Position the label absolutely */
    //top: 50%; /* Position halfway down the parent */
    //left: 50%; /* Position halfway across the parent */
    //transform: translate(-50%, -50%); /* Adjust position to truly center */
    //width: fit-content; /* Adjust width to fit content */
    ////ends
    cursor: pointer;
    display: inline-block;
    font-family: 'Rubik', sans-serif;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    padding: 1rem 50px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;


    &.btn-2 {
        background-color: $primary-light;
        border-radius: 50px;
        overflow: hidden;

        &::before {
            color: #fff;
            content: "📥";
            font-size: 100%;
            height: 100%;
            right: 120%;
            line-height: 3.3;
            position: absolute;
            top: 0px;
            transition: all 0.3s;
        }

        &:hover {
            background-color: darken($primary-dark, 10%);

            &::before {
                right: 80%;
            }
        }
    }
}

.removeButton{
    background-color: #af0b0b;
    border-radius: 2rem;
    overflow: hidden;

    span {
        display: inline-block;
        height: 100%;
        transition: all 0.3s;
        width: 100%;
    }

    &::before {
        color: #fff;
        content: "X";
        font-size: 130%;
        height: 100%;
        left: 0;
        line-height: 2.6;
        position: absolute;
        top: -180%;
        transition: all 0.3s;
        width: 100%;
    }

    &:hover {
        background-color: darken(#ee6d9e, 30%);

        span {
            transform: translateY(300%);
        }

        &::before {
            top: 0;
        }
    }

}
.image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; // Spacing between each image container
}

.uploaded-image {
    width: 100px;
    height: 100px;
    border-radius: .6rem;
    object-fit: cover; // To maintain aspect ratio
    order: 1;
}

.remove-button {
    margin-right: 10px; // Space between button and image
    padding: 5px 10px;
    border: none;
    background-color: $red-light; // Example color
    color: white;
    border-radius: .6rem;
    cursor: pointer;
    order: 2;

    &:hover {
        background-color: $favorite; // Slightly lighter on hover
    }
}
