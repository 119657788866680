@import "../../styles/utils";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.floatingButtons {
  position: fixed;
  right: 2rem;
  bottom: 2rem;

  display: flex;
  align-items: center;
}

.callWaiterButton {
  width: 7.2rem;
  height: 7.2rem;
  outline: none !important;
  border: none;
  border-radius: 3.6rem;
  background-color: $secondary;
  transition: all .1s linear;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2),
        inset 0 0 34px rgba(0, 0, 0, 0.1);


  color: white;
  font-weight: bold;
  font-size: 1.8rem;


  &.disabled {
    pointer-events: none;
  }

  &.pop {
    transform: scale(1.2);
  }

  svg {
    width: 2.6rem;
    height: 2.6rem;
    path {
      stroke: white;
    }
  }
}

.wishlistButton {
  position: relative;
  width: 7.2rem;
  height: 7.2rem;
  margin-left: 1.5rem;
  outline: none !important;
  border: none;
  border-radius: 3.6rem;
  background-color: $primary;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.4),
        inset 0 0 34px rgba(0, 0, 0, 0.3);

  .wishlistButtonCount {
    position: absolute;
    top: -.3rem;
    right: -.3rem;
    width: 2.2rem;
    height: 2.2rem;
    background-color: white;
    border-radius: 1.1rem;
    color: $primary;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.2rem;
  }

  &.wishlist {
    background-color: $favorite;

    .wishlistButtonCount {
      color: $favorite;
    }
  }

  svg {
    width: 2.6rem;
    height: 2.6rem;
    path {
      stroke: white;
    }
  }
}
//.parent-container {
//  position: relative;
//  /* other styles for the container */
//}
.signupButton {
  //position: fixed; /* Use fixed positioning */
  left: 50%; /* Position the left edge of the button at the center of the viewport */
  //bottom: 3rem; /* Adjust this value based on your design needs */
  transform: translateX(-50%); /* Shift the button leftwards by half its own width */
  animation: pulse 2s infinite;
  //background-color: $red-light; // Or any color that stands out
  position: sticky; // Stick to the bottom of the viewport
  bottom: 1rem; // Distance from the bottom of the viewport
  z-index: 10; // Make sure it's above other elements
  width: 14.0rem;
  height: 6rem;
  outline: none !important;
  border: none;
  border-radius: 2.5rem;
  //box-shadow: 0 10px 50px rgba(0, 0, 0, 0.4),
  //      inset 0 0 34px rgba(0, 0, 0, 0.3);

  //font-family: 'Roboto', sans-serif;
  font-size: 1.7rem; // Adjust the font size as needed
  //font-weight: bold;
  color: white;
  background: linear-gradient(45deg, $primary-light, $primary);
  box-shadow: 0 10px 6px rgba(0, 0, 0, 0.1); // A subtle shadow
  transition: all 0.3s ease-in-out;


  .signupText{
    color: white;
    font-size: 1.7rem;
    //font-weight: bold;
  }

  svg {
    width: 2.6rem;
    height: 2.6rem;
    path {
      stroke: white;
    }
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($primary, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($primary, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}