@import "../../styles/utils";
.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.45); // White with slight transparency
}

.spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid $gray-light; // Light gray border
    border-top-color: $primary; // Primary color for the spinner top border
    animation: spinner 0.6s linear infinite;
}

.loading-text {
    margin-top: 20px;
    color: $text-primary; // Dark color for text
    font-size: 16px;
    text-align: center;
}

@keyframes spinner {
    to { transform: rotate(360deg); }
}

// Responsive adjustments
@media (max-width: 600px) {
    .spinner {
        width: 40px;
        height: 40px;
    }
    .loading-text {
        font-size: 16px;
    }
}
