.menu-preview-container {
  position: relative; /* Only add this line */
  height: 80%; /* Adjust height as needed */
  width: 380px; /* Adjust width as needed */
  padding: 10px;
  box-sizing: border-box;
}

.menu-preview-iframe {
  border: 1px solid #d3d3d3; /* Light grey border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border-radius: 10px; /* Rounded corners for a modern look */
  height: calc(100vh * 0.85); /* Adjust based on the earlier 70% smaller requirement */
  width: 380px; /* Adjust width as needed */
  /* Other styles */
}


.menu-preview-refresh {  position: absolute; /* Position the button absolutely relative to its nearest positioned ancestor */
  top: -15px; /* Position from the top of the container */
  left: 105%; /* Center the button relative to the container */
  transform: translateX(-50%); /* Align the center of the button with its left edge */
  background: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure it's above other elements */

  &:hover {
    background: #f0f0f0;
  }

  svg {
    width: 27px;
    height: 27px;
  }
}

