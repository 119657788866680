@import "../../../styles/utils";

.account-page {
    display: flex;

    @include tablet {
        flex-direction: column;
    }
}

.account-page-sidebar {
    flex: 1.2;
    padding: 10rem 7rem 0 7rem;
    background-color: #fff;
    min-height: 100vh;
    position: relative;

    @include tablet {
        padding: 2rem;
        height: auto;
        min-height: unset;

        .list-menu {
            margin-top: 1rem;
        }
    }
}

.account-page-content {
    flex: 3;
    padding: 10rem 7rem 0 7rem;

    @include tablet {
        padding: 2rem 2rem 7rem 2rem;
        height: auto;
        min-height: unset;
    }
}

.business-logo {
    width: 20rem;
    height: 20rem;
    border-radius: 5rem;
    filter: drop-shadow(0px 40px 40px rgba(0, 0, 0, 0.1));

    margin: auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.logout-button {
    position: absolute;
    bottom: 7rem;
    margin: auto;
    width: calc(100% - 14rem);

    svg {
        path {
            stroke: white;
        }
    }

    @include tablet {
        position: relative;
        bottom: unset;
        width: 100%;
    }
}

.account-page-popup {
    display: flex;
    align-items: center;
    height: 5.2rem;
    color: white;
    z-index: 999;
    background-color: $primary;

    position: fixed;
    left: 50%;
    bottom: -5.3rem;
    transform: translateX(-50%);

    padding: 0 2rem;
    border-radius: 4rem;

    transition: all .2s linear;

    svg {
        margin-right: 1rem;
        circle, path {
            stroke: white;
        }
    }

    &.active {
        bottom: 2.4rem;
    }

    @include tablet {
        width: 95vw;
    }
}
// Subscription Component Styles
.subscription-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.subscription-option {
    position: relative; // Needed for absolute positioning of the badge
    border: 2px solid #e7e7e7;
    border-radius: 8px;
    margin: 1rem 0;
    padding: 2rem;
    width: 90%;
    max-width: 350px;
    transition: border-color 0.3s ease;

    &:hover {
        border-color: $primary;
    }
}

.subscription-details {
    text-align: center;
}

.subscription-title {
    color: $secondary;
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.subscription-price {
    color: $primary-dark;
    font-size: 2.6rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.subscription-description {
    color: #666;
    margin-bottom: 2.5rem;
}

.subscribe-button {
    background-color: $primary;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1.6rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: $primary-dark;
    }
}

// Active Subscription Styles
.subscription-option.active {
    border-color: $primary-light;
}

.active .subscription-title,
.active .subscription-price {
    color: $primary-light;
}

.active .subscribe-button {
    background-color: $primary-light;
    &:hover {
        background-color: $primary;
    }
}

.subscribe-button.dimmed {
    background-color: #ccc;
    cursor: not-allowed;

    &:hover {
        background-color: #ccc;
    }
}


// Active Plan button
.manage-subscription-button {
    background-color: $secondary-light;
    &:hover {
        background-color: darken($secondary-light, 5%);
    }
}

.subscription-option.active {
    border-color: $primary;
    background-color: $primary-light;
    background-color: rgba($primary-light, 0.2); // Slight background tint
}

// Overlay
.subscription-overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
    z-index: 1000; // Ensure it's above other content
}

// Popup
.subscription-popup {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    text-align: center;
    position: relative;
}

.subscription-popup-header {
    font-size: 2rem;
    color: $primary-dark;
    margin-bottom: 1.2rem;
}

.subscription-popup-content {
    margin-bottom: 2rem;
}

.subscription-popup-actions {
    display: flex;
    justify-content: space-evenly;
}

.subscription-action-button {
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0.5rem;
}

.cancel-subscription-button {
    background-color: $favorite;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($favorite, 10%);
    }
}

.switch-subscription-button {
    background-color: $secondary;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($secondary, 10%);
    }
}

.close-button {
    position: absolute;
    top: 2px;
    right: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 3.5rem;
    color: $secondary;
}

.subscription-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    position: absolute; // Make sure the parent is relative.
    top: -10px;
    right: -10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.subscription-expiry-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0bf4a; /* Keeping it more neutral rather than red */
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    position: absolute; // Make sure the parent is relative.
    top: -10px;
    right: -60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

//.subscription-expiry-badge {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    background-color: #e0bf4a; /* Keeping it more neutral rather than red */
//    color: #fff;
//    font-size: 1.2rem; /* Slightly smaller to fit longer text */
//    font-weight: bold;
//    padding: 0.5rem 1rem;
//    border-radius: 15px;
//    position: absolute;
//    top: -10px;
//    left: -50px;
//    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//    z-index: 10;
//    white-space: nowrap; /* Ensure the text stays in one line */
//}

//.subscription-expiry-badge {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    background-color: #e0bf4a; /* Red for alert */
//    color: #fff;
//    font-size: 1rem;
//    font-weight: bold;
//    padding: 0.5rem 1rem;
//    border-radius: 15px;
//    position: absolute;
//    top: -10px; /* Adjust as needed */
//    left: -10px; /* Adjust as needed */
//    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//    z-index: 10; /* Ensure it's above other elements */
//}

