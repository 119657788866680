@import "../../styles/utils";

.button {
    height: 5.2rem;
    border-width: .1rem;
    border-style: solid;
    font-weight: 500;
    outline: none !important;
    border-radius: 1rem;
    transition: all linear .1s;
    font-size: 1.6rem;
    padding: 0 2rem;

    //overflow: hidden;
    white-space: nowrap;
    //text-overflow: ellipsis;
}

.button-outline {
    background: transparent;
    border-radius: 2rem;
}

.button-filled {
    color: white;
    border-radius: 2rem;
}

.full {
    width: 100%;
}

.auto {
    width: auto;
}

.small {
    height: 3.8rem;
    font-size: 1.4rem;
    padding: .8rem 1rem;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;

    position: static;
    width: 3.6rem;
    height: 3.6rem;

    padding: 0;
    background: $background;
    border-radius: 2rem;
    border-color: $background;

    svg {
        path {
            //stroke: $gray;
        }
    }
}

.disabled {
    color: white;
    pointer-events: none;
    border-color: $gray;
    background-color: $gray;
}

.green {
    @include button-filled($primary, $primary-dark, $primary-light);
}

.red {
    @include button-filled($red, $red-dark, $red-light);
}

.gray {
    @include button-filled($gray, $gray-dark, $gray-light);
}

.white {
    @include button-filled(white, white, white);
    color: #3B3DBF;
}
