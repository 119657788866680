.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.languages-dropdown {
  position: absolute;
  top: 3rem;
  right: 4rem;
}
/* Style for MenuPreview */
.menu-preview {
  width: 20%; /* Take up half of the container */
  max-width: 375px; /* Maximum width can be the width of a typical mobile device */
  height: 30vh; /* Sets the height to 30% of the viewport height */
  /* Other styles remain the same */
}
.content-wrapper.show-menu-preview {
  // Add specific styles for when the menu preview is shown
  // Example: Adjust flex direction, gap, or other properties as needed
  display: flex;
  flex-direction: row; /* Align children horizontally */
  gap: 20px; /* Adjust the space between the flex items */
  height: 100vh; /* Set to the full height of the viewport */
  overflow: hidden; /* Prevents overflow to keep the layout clean */
  // Add any other styles you want to apply when the menu preview is shown
}

.custom-toast {
  border-radius: 10px; /* Adjust as needed */
  /* Add other styles if necessary */
}