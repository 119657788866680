.tableQrCode {
  position: relative;
  width: 112mm;
  height: 112mm;
  background-color: #151313;

  canvas {
    width: 72mm !important;
    height: 72mm !important;
    position: absolute;
    top: 50%;
    left: 20mm;
    //left: 10mm;
    transform: translateY(-50%);
  }
}

.tableQrCodeNumber {
  position: absolute;
  font-size: 2.5rem;
  top: 6mm;
  //top: 11mm;
  right: 12mm;

  width: 12.5mm;
  height: 12.5mm;
  background-color: #FDAF2A;
  color: #151313;
  border-radius: 8mm;

  line-height: 12.5mm;
  text-align: center;
}

.tableQrCodeTextContent {
  position: absolute;
  //top: 36%;
  //left: 45%;
  top: 57%;
  left: 46%;
  transform: translateY(-50%);

  text-align: center;
  width: 87mm;
}

.tableQrCodeTitle {
  color: #FDAF2A;

  font-size: 3.7rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 5rem;
}

.tableQrCodeSubtitle {
  color: #FDAF2A;

  font-size: 2.8rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 3rem;
}

.tableQrCodeText {
  color: #FDAF2A;
  font-size: 2.5rem;
  line-height: 3.6rem;


  //text-transform: uppercase;
}

.tableQrCodeLogo {
  height: 12mm;
  width: 57mm;
  top: 27%;
  left: 46.5%;
  position: absolute;
  transform: translateY(-50%);
  //height: 20mm;
  //width: 40mm;
  //position: absolute;
  //top: 90%;
  //left: 73%;
  //transform: translateY(-50%);
}

.tableQrCodeNfc {
  height: 25%;
  width: 16%;
  position: absolute;
  top: 65%;
  left: 61%;
  transform: translateY(-50%);
}

